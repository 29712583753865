import * as React from "react"
import { Link } from "gatsby"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import PostLink from "../components/post-link"


const Layout = ({ children }) => {
 

  return (
    
    <div class="bg-wood">
      <Navbar />
      <div class="relative pt-50"></div>
      <main class="relative pt-50 md:w-2/3 skYellow container mx-auto">{children}</main>
      
      <Footer />


    </div>
  )
}

export default Layout
